<template>
  <div id="transcription-display">
    <pre id="transcribed-text" v-if="transcribedText">{{
      transcribedText
    }}</pre>
    <p id="placeholder" v-else>Transcribed text will appear here</p>
    <button id="download-button" @click="download" :disabled="!transcribedText">
      Download Transcription
    </button>
  </div>
</template>

<script>
export default {
  name: "transcription-display",
  props: {
    transcribedText: String,
  },
  methods: {
    download() {
      if (!this.transcribedText || this.transcribedText == "") {
        return;
      }

      const blob = new Blob([this.transcribedText], { type: "text/plain" });
      const url = URL.createObjectURL(blob);

      // Create a link element
      const link = document.createElement("a");

      // Set the href and download attributes for the link
      link.href = url;
      link.download = "transcription.txt";

      // Append the link to the body
      document.body.appendChild(link);

      // Click the link
      link.click();

      // Cleanup - remove the link from the body
      document.body.removeChild(link);

      // Track download button clicks
      this.$gtag.event("click", {
        event_category: "Transcription",
        event_label: "Download",
        value: 1,
      });
    },
  },
};
</script>

<style scoped>
#transcription-display {
  padding: 1em;
  background: #f4f4f4;
  border-radius: 5px;
  margin-top: 2em;
  margin-bottom: 2em;
  max-height: 400px;
  width: 800px;
  overflow-y: auto;
}

#transcribed-text {
  white-space: pre-wrap; /* Preserve line breaks */
  word-wrap: break-word; /* Break long words */
  color: #333;
}

#placeholder {
  color: #aaa; /* Gray color for placeholder text */
  text-align: center;
}

#download-button {
  padding: 1em 2em;
  background: #333;
  color: #fff;
  border: none;
  cursor: pointer;
  margin-top: 1em;
  display: inline-block;
}

#download-button:disabled {
  background: #aaa; /* Gray color for disabled button */
  cursor: not-allowed; /* Cursor style for disabled elements */
}
</style>
