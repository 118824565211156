<template>
  <main id="terms-of-service-view">
    <h1>TERMS OF SERVICE</h1>
    <p class="effective-date">Effective Date: July 22, 2023</p>

    <section>
        <h2>1. ACCEPTANCE OF TERMS</h2>
        <p>These Terms of Service ("Terms") govern your use of the Speak to Text web application ("App"). The App is provided by xyfuture, LLC ("Company", "we", "us", or "our"). By accessing or using our App, you agree to abide by these Terms and to comply with all applicable laws and regulations. If you do not agree with these Terms, you are prohibited from using or accessing this App.</p>
    </section>

    <section>
        <h2>2. USAGE RULES</h2>
        <p>You are authorized to use our App only for lawful purposes. You may not use our App in a manner which could damage, disable, overburden, or impair the services or interfere with any other party's use and enjoyment of the services. We reserve the right to terminate your access to the services if we determine, at our sole discretion, that you are engaged in prohibited activities.</p>
    </section>

    <section>
        <h2>3. USER CONTENT</h2>
        <p>You understand and agree that you are solely responsible for all data, text and audio files that you upload and transcribe using our App.</p>
    </section>

    <section>
        <h2>4. COPYRIGHT & INTELLECTUAL PROPERTY</h2>
        <p>All content provided on the App is owned by or licensed to us and is subject to copyright and other intellectual property rights. You may not reuse, redistribute, or copy any portion of the App without our express written permission.</p>
    </section>

    <section>
        <h2>5. THIRD-PARTY SERVICES</h2>
        <p>Our service integrates with Google Ads and OpenAI's Whisper-1 model. The use of these third-party services are subject to their respective Terms of Service and Privacy Policies.</p>
    </section>

    <section>
        <h2>6. DISCLAIMER</h2>
        <p>The transcription services provided by our App, and the results thereof, are intended for informational purposes only. We do not guarantee the accuracy, completeness, or reliability of any transcription results.</p>
    </section>

    <section>
        <h2>7. INDEMNIFICATION</h2>
        <p>You agree to indemnify and hold us harmless from and against any claims, liabilities, damages, losses, and expenses, resulting from your use of the App or breach of these Terms.</p>
    </section>

    <section>
        <h2>8. LIMITATION OF LIABILITY</h2>
        <p>In no event shall xyfuture, LLC or its suppliers be liable for any damages arising out of the use or inability to use the App.</p>
    </section>

    <section>
        <h2>9. CHANGES TO TERMS</h2>
        <p>We may modify these Terms at any time without notice to you. By continuing to access or use our App after revisions become effective, you agree to be bound by the revised Terms.</p>
    </section>

    <section>
        <h2>10. CONTACT US</h2>
        <p>If you have any questions about these Terms, contact us on <a href="mailto:legal@speaktotext.io">legal@speaktotext.io</a>.</p>
    </section>

    <p class="ending-note">Thank you for choosing Speak to Text.</p>
  </main>
</template>

<script>
export default {
  name: "TermsOfService-View",
  data() {
    return {};
  },
};
</script>

<style scoped>
#terms-of-service-view {
  width: 80%;
  margin: auto;
}

h1, h2 {
  color: #333;
}

h2 {
  margin-top: 2em;
}

.effective-date, .ending-note {
  font-weight: bold;
}

a {
  color: #1a0dab;
}

/* Add additional styles as needed */
</style>
