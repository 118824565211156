<template>
  <transition name="slide">
    <div v-if="showBanner" class="banner-container">
      <p>
        We use 🍪 for more details check our policy
        <router-link to="privacy-policy">here</router-link>
      </p>
      <button @click="okBannerClicked">OK</button>
    </div>
  </transition>
</template>

<script>
import { defineComponent, inject } from "vue";
import useCookies from "../useCookies";

export default defineComponent({
  name: "CookieBanner",
  setup() {
    const gtag = inject("gtag");
    const { showBanner, okClicked } = useCookies(gtag);
    const okBannerClicked = () => okClicked();
    return {
      showBanner,
      okBannerClicked,
    };
  },
});
</script>

<style scoped>
.banner-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #f9f9f9; /* Light grey color */
  color: #333333; /* Dark text color */
  padding: 20px;
  text-align: center;
  z-index: 1000; /* Ensure the banner stays on top */
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.15);
  /* border-top: 2px solid #f2f2f2;  */
}

.banner-container p {
  margin: 0;
  padding: 0;
  display: inline-block;
  margin-right: 10px;
  border: black top solid;
}

.banner-container button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}

.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.5s;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
}
</style>
