<template>
  <main id="home-view">
    <div class="long">
      <p>
        This free online tool transcribes your audio files into text, applying
        high-precision methods backed by OpenAI's Whisper model. Unlike other
        services, this tool does not ask for your email address, offers mass
        transcription and accepts files up to {{ maxFileSize }}MB in size.
      </p>

      <ol>
        <li>
          <p>
            Click the <i>BROWSE</i> button and select the audio file you wish to
            transcribe. Supported formats include mp3, mp4, wav, m4a, and more.
            You can also drag file to the drop area to start uploading.
          </p>
        </li>
        <li>
          <p>
            Relax and wait while our tool transcribes your file, automatically
            choosing the proper transcription parameters to achieve the highest
            accuracy.
          </p>
        </li>
      </ol>
    </div>
    <div class="content-wrapper">
      <div class="content">
        <InputForm
          :maxFileSize="maxFileSize"
          @transcription-complete="handleTranscription"
        />
      </div>

      <TranscriptionDisplay :transcribedText="transcribedText" />
    </div>

    <div class="long">
      <h1>Free Audio to Text Transcription with SpeakToText.io</h1>
      <p>
        In a rapidly digitalized world, <i>spoken-word content</i> and
        <i>audio files</i>
        are increasingly valuable. Podcasts, interviews, webinars, voice
        notes... but how to convert them into text? That's where SpeakToText.io,
        offering free audio to text transcription service, steps in.
      </p>
      <p>
        SpeakToText.io is an unrivaled tool to transcribe audio to text for
        free, designed with simplicity and accuracy at its core. Our tool can
        convert audio files from any language to text in any language, making it
        universally effective and one of the best transcription services
        available online.
      </p>
      <h1>
        Transcribe Audio Free: Why opt for a Text Version of Your Audio Files?
      </h1>

      <p>
        Whether you're a journalist needing text from an audio interview, a
        student desiring lecture notes from a recording, or a podcast enthusiast
        who prefers reading over listening, SpeakToText.io's audio to text
        transcription service is the solution.
      </p>

      <p>
        Transcribing audio to text not only provides a documented version of
        your files but also enhances content's readability and accessibility. By
        transcribing audio free, SpeakToText.io ensures that your content is
        accessible to everyone, including individuals with hearing impairments.
      </p>

      <h1>How to Transcribe Audio to Text for Free with SpeakToText.io?</h1>
      <p>
        Converting audio to text with SpeakToText.io is a straightforward
        process. Upload your audio file, press the "Transcribe" button, and let
        our state-of-the-art transcription tool do the work.
      </p>

      <p>
        Transcriptions are completed in real-time, and thanks to our utilization
        of OpenAI's Whisper model, high accuracy is assured. Once transcribed,
        your text is ready for instant download and use.
      </p>

      <p>
        What sets SpeakToText.io apart as the best transcription service? It's
        completely free. Our primary goal is to make audio to text transcription
        easy and accessible for everyone.
      </p>

      <h1>Your Data is Safe with SpeakToText.io</h1>

      <p>
        At SpeakToText.io, we prioritize your privacy and security. None of your
        audio files are stored on our servers, ensuring your data's
        confidentiality.
      </p>

      <p>
        For data sent through our OpenAI-powered API, OpenAI's policy as of
        March 1, 2023, reinforces our commitment to your privacy. OpenAI no
        longer uses data submitted through our API for model training or
        improvement.
      </p>

      <p>
        Also, this data is retained for a maximum of 30 days for abuse and
        misuse monitoring purposes. After this period, all data is deleted.
      </p>

      <p>
        With SpeakToText.io's free audio transcription service, you can
        transcribe audio free of worry. We ensure that your data's
        confidentiality and security are never compromised.
      </p>
    </div>

    <footer class="share-footer">
      <p class="share-footer-title">Like it? Share it and support!</p>
      <ul class="share-footer-items">
        <li class="share-item facebook">
          <a
            class="share__link share__link_fb"
            data-share=""
            rel="noopener"
            target="_blank"
            href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fspeaktotext.io%2F"
            title="Please share this page via Facebook"
          >
            <svg class="share__icon" viewBox="0 0 11 18" width="11" height="18">
              <use xlink:href="#facebook">
                <g id="facebook">
                  <path
                    d="M4.2057 17.0975V8.88324H0.937988V6.14514H4.2057V4.67478C4.2057 1.89082 5.82403 0.668945 8.58524 0.668945C9.90784 0.668945 10.6071 0.751088 10.938 0.788737V3.40704H9.05497C7.88268 3.40704 7.4734 3.92523 7.4734 4.97529V6.14514H10.9086L10.4421 8.88324H7.4734V17.0975H4.2057Z"
                  ></path>
                </g>
              </use>
            </svg>
          </a>
        </li>
        <li class="share-item twitter">
          <a
            data-share=""
            rel="noopener"
            target="_blank"
            href="https://twitter.com/intent/tweet?text=Convert Audio in any language to Text for FREE!&url=https%3A%2F%2Fspeaktotext.io%2F"
            class="share__link share__link_tw"
            title="Please share this page via Twitter"
          >
            <svg class="share__icon" viewBox="0 0 20 16" width="20" height="16">
              <use xlink:href="#twitter">
                <g id="twitter">
                  <path
                    d="M19.938 0.695801C19.197 1.11175 17.7102 1.67863 16.8362 1.84213C16.8106 1.84842 16.7897 1.8565 16.765 1.86279C15.9926 1.14229 14.9343 0.695801 13.763 0.695801C11.4022 0.695801 9.48799 2.50603 9.48799 4.7385C9.48799 4.85619 9.47754 5.0727 9.48799 5.18769C6.30264 5.18769 3.87824 3.61014 2.13974 1.59418C1.95069 2.04337 1.86804 2.75309 1.86804 3.41968C1.86804 4.67831 2.90829 5.91448 4.52804 6.68079C4.22974 6.75356 3.90104 6.80567 3.55904 6.80567C3.00709 6.80567 2.42284 6.66821 1.88799 6.25137C1.88799 6.26664 1.88799 6.28101 1.88799 6.29719C1.88799 8.05621 3.86209 9.25375 5.61769 9.58704C5.26144 9.78558 4.54324 9.80535 4.19269 9.80535C3.94569 9.80535 3.07169 9.69844 2.83799 9.65712C3.32629 11.099 5.08759 11.9093 6.76624 11.9381C5.45334 12.9119 4.54229 13.2731 1.85379 13.2731H0.937988C2.63659 14.3026 4.79974 15.0707 6.96764 15.0707C14.0261 15.0707 18.038 9.98323 18.038 5.18769C18.038 5.11043 18.0361 4.94872 18.0332 4.78611C18.0332 4.76994 18.038 4.75467 18.038 4.7385C18.038 4.71424 18.0304 4.69089 18.0304 4.66663C18.0275 4.54445 18.0247 4.43036 18.0218 4.37106C18.7723 3.85899 19.4231 3.22024 19.938 2.49256C19.2492 2.78183 18.5101 2.97588 17.734 3.06392C18.5263 2.61474 19.652 1.54387 19.938 0.695801Z"
                  ></path>
                </g>
              </use>
            </svg>
          </a>
        </li>
        <li class="share-item reddit">
          <a
            data-share=""
            rel="noopener"
            target="_blank"
            href="https://www.reddit.com/submit?url=https%3A%2F%2Fspeaktotext.io%2F&title=Convert%20Audio%20in%20any%20language%20to%20Text%20For%20FREE!"
            class="share__link share__link_rd"
            title="Please share this page via Reddit"
          >
            <svg class="share__icon" viewBox="0 0 38 37" width="38" height="37">
              <use xlink:href="#reddit">
                <g id="reddit">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M31.5422 6.83425C33.2607 6.83425 34.6538 5.44115 34.6538 3.72267C34.6538 2.00419 33.2607 0.611084 31.5422 0.611084C30.3429 0.611084 29.3021 1.28963 28.7826 2.28376L21.6525 0.690398C20.9759 0.539178 20.3054 0.967644 20.1584 1.64526L17.8726 12.1802C13.7569 12.3236 10.0372 13.5629 7.25574 15.5049C6.50137 14.713 5.4366 14.2195 4.25657 14.2195C1.96924 14.2195 0.11499 16.0737 0.11499 18.3611C0.11499 19.9969 1.06341 21.4113 2.44038 22.0842C2.28391 22.7459 2.20239 23.4262 2.20239 24.1203C2.20239 30.7223 9.57768 36.0743 18.6756 36.0743C27.7735 36.0743 35.1487 30.7223 35.1487 24.1203C35.1487 23.4268 35.0674 22.747 34.9111 22.0859C36.29 21.4136 37.2399 19.9983 37.2399 18.3611C37.2399 16.0737 35.3857 14.2195 33.0984 14.2195C31.9175 14.2195 30.8521 14.7137 30.0977 15.5065C27.3001 13.5525 23.5528 12.3095 19.408 12.1779L21.5709 2.20915L28.4307 3.74212C28.4412 5.45165 29.8303 6.83425 31.5422 6.83425ZM15.0193 22.5027C15.0193 24.2336 13.6161 25.6368 11.8851 25.6368C10.1542 25.6368 8.75098 24.2336 8.75098 22.5027C8.75098 20.7717 10.1542 19.3685 11.8851 19.3685C13.6161 19.3685 15.0193 20.7717 15.0193 22.5027ZM28.676 22.5027C28.676 24.2336 27.2728 25.6368 25.5419 25.6368C23.8109 25.6368 22.4077 24.2336 22.4077 22.5027C22.4077 20.7717 23.8109 19.3685 25.5419 19.3685C27.2728 19.3685 28.676 20.7717 28.676 22.5027ZM25.5425 30.0004C25.8471 29.7197 25.8664 29.2452 25.5856 28.9406C25.3049 28.6361 24.8304 28.6168 24.5258 28.8975C23.1982 30.1214 20.8548 30.7098 18.4496 30.6578C17.2658 30.6322 16.1118 30.4513 15.1238 30.1417C14.1262 29.829 13.349 29.4015 12.8663 28.9187C12.5734 28.6258 12.0985 28.6258 11.8056 28.9187C11.5127 29.2116 11.5127 29.6865 11.8056 29.9794C12.5207 30.6945 13.5465 31.2193 14.6752 31.573C15.8135 31.9298 17.1093 32.1292 18.4172 32.1575C20.9955 32.2132 23.8034 31.6037 25.5425 30.0004Z"
                  ></path>
                </g>
              </use>
            </svg>
          </a>
        </li>
      </ul>
    </footer>
  </main>
</template>

<script>
import InputForm from "../components/InputForm.vue";
import TranscriptionDisplay from "../components/Transcription-Display.vue";
import Config from "../config";

export default {
  name: "Home-View",
  components: {
    InputForm,
    TranscriptionDisplay,
  },
  data() {
    return {
      maxFileSize: Config.global.maxFileSize,
      transcribedText: "",
    };
  },
  methods: {
    handleTranscription(transcription) {
      this.transcribedText = transcription.text;
    },
  },
};
</script>

<style scoped>
main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

main h1 {
  color: #333;
}

main p {
  color: #666;
}

.content {
  max-width: 800px;
  text-align: center;
  padding: 20px;
}

.long {
  line-height: 1.4;
  font-size: 1.07em;
  padding: 1em 0 2em 0;
  max-width: 1000px;
}

.long h1 {
  font-size: 1.4em;
  text-align: left;
  color: #38404b;
  border-bottom: 2px solid #f2f2f2;
}

.long p {
  margin: 1em 0 1em 0;
}

.content-wrapper {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.share-footer {
  text-align: center;
  padding-bottom: 2em;
}
.share-footer-title {
  font-weight: bold;
  font-size: 1.3em;
  letter-spacing: 0.02em;
  margin-bottom: 0.5em;
  color: black;
}
.share-footer-items {
  list-style: none;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 1em;
  margin-bottom: 1.8em;
  color: #b3b3b3;
}
.share-item {
  margin-right: 1em;
}

.share__link_fb {
  width: 1.2em;
  height: 1.8em;
  color: #4267b2;
  filter: grayscale(100%) opacity(55%);
}
.share__link {
  display: inline-block;
  vertical-align: bottom;
  flex-shrink: 0;
  transform: translateZ(0);
  transition: filter 0.2s ease-out;
  outline: none;
}
.share__icon {
  width: 100%;
  height: 100%;
  fill: currentColor;
}
.share__link_tw {
  width: 2em;
  height: 1.5em;
  color: #1da1f2;
  filter: grayscale(100%) opacity(70%);
}
.share__link_rd {
  width: 2em;
  height: 2em;
  color: #ff4500;
  filter: grayscale(100%) opacity(60%);
}
</style>
