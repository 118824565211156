<template>
  <header>
    <div class="header-content">
      <!-- <img src="logo.png" :alt="title" class="logo"> -->
      <router-link to="/" custom v-slot="{ navigate, href }">
        <h2 id="logo" @click="navigate" :href="href">{{ title }}</h2>
      </router-link>
      <nav>
        <router-link to="/" exact>Home</router-link>
        <router-link to="/contact-us">Contact Us</router-link>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: "AppHeader",
  props: {
    title: String,
  },
};
</script>

<style scoped>
#logo {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

header {
  background: #f4f4f4;
}

.header-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-content .logo {
  height: 40px;
}

header nav a {
  color: #333;
  text-decoration: none;
  margin-left: 20px;
}

header nav a:hover {
  text-decoration: underline;
}
</style>
