<template>
  <ErrorNotification
    v-if="errorMessage"
    :errorMsg="errorMessage"
    :onDismiss="onErrorDismiss"
  />

  <h1>Simple Audio Transcriber</h1>
  <p>
    Upload your audio file below and receive a transcribed text file in seconds
    for <span>FREE</span>!
  </p>

  <!-- <div class="language-container">
        <select id="input-language">
            <option value="" selected disabled>Sound Language</option>
            <option value="aut">Auto</option>
            <option value="en">English</option>
            <option value="fr">French</option>
        </select>
        
        <select id="output-language">
            <option value="" selected disabled>Transcribe Language</option>
            <option value="aut">Auto</option>
            <option value="en">English</option>
            <option value="fr">French</option>
        </select>
    </div> -->

  <!-- <form>
    <div class="upload-container">
      <input
        type="file"
        id="fileUpload"
        @change="onFileChange"
        accept=".mp3, .mp4, .mpeg, .mpga, .m4a, .wav, webm"
      />
      <input
        type="text"
        id="file-name"
        :value="selectedFile ? selectedFile.name : ''"
        readonly
      />
      <button type="button" id="browse" :disabled="isLoading">Browse</button>
    </div>
  </form> -->

  <FileDropzone
    :maxFileSize="maxFileSize"
    @file-changed="fileChangedHandler"
    @error="errorHandler"
    :isDisabled="isLoading"
    ref="fileDropzoneRef"
  />

  <p id="acceptedFormats">
    Accepted formats: mp3, mp4, mpeg, mpga, m4a, wav, and webm.<br />File size
    can not exceed {{ maxFileSize }}MB
  </p>

  <!-- <button type="submit" id="transcribe-button" @click="transcribe">Transcribe</button> -->
  <button
    type="submit"
    id="transcribe-button"
    @click="transcribe"
    :disabled="isLoading"
  >
    <span v-if="isLoading">Transcribing...</span>
    <span v-else>Transcribe</span>
  </button>
</template>

<script>
import ErrorNotification from "./ErrorNotification";
import openAiService from "../services/openAiService";
import FileDropzone from "./FileDropzone";

import { ref, inject } from "vue";
import { useReCaptcha } from "vue-recaptcha-v3";
import useCookies from "../useCookies";

export default {
  name: "InputForm",
  emits: ["transcription-complete"],
  props: {
    file: String,
    maxFileSize: Number,
  },
  components: {
    ErrorNotification,
    FileDropzone,
  },
  setup(props, ctx) {
    const errorMessage = ref("");
    const selectedFile = ref(null);
    const isLoading = ref(false); // Added isLoading state to track progress
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
    const gtag = inject("gtag");
    const { allowCookies } = useCookies(gtag);
    const fileDropzoneRef = ref(null);

    // onMounted(() => {
    //   document.getElementById("browse").addEventListener("click", function () {
    //     document.getElementById("fileUpload").click();
    //   });
    // });

    const fileChangedHandler = (file) => {
      selectedFile.value = file;
    };

    const errorHandler = (message) => {
      errorMessage.value = message;
    };

    const onFileChange = (e) => {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length || files.length <= 0) {
        selectedFile.value = null;
        if (fileDropzoneRef.value) {
          fileDropzoneRef.value.clearFile();
        }
        return;
      }

      if (files[0].size > props.maxFileSize * 1048576) {
        errorMessage.value = `File is too big! The maximum allowed size is ${props.maxFileSize}MB.`;
        e.target.value = "";
        selectedFile.value = null;
        if (fileDropzoneRef.value) {
          fileDropzoneRef.value.clearFile();
        }
        return;
      }

      errorMessage.value = "";

      selectedFile.value = files[0];
    };

    const onErrorDismiss = () => {
      errorMessage.value = "";
    };

    const transcribe = async () => {
      gtag.event("click", {
        event_category: "Transcription",
        event_label: "Transcribe Button Press",
        value: 1,
      });

      if (!selectedFile.value) return;
      if (
        !allowCookies ||
        allowCookies === undefined ||
        allowCookies === false ||
        !allowCookies.value ||
        allowCookies.value === undefined ||
        allowCookies.value === false
      ) {
        gtag.event("failed", {
          event_category: "Transcription",
          event_label: "Transcribe Failed because of cookies",
          value: 1,
        });
        errorMessage.value = "Cookies are required to complete the request.";
        return;
      }

      errorMessage.value = "";

      isLoading.value = true; // Start loading

      // (optional) Wait until recaptcha has been loaded.
      await recaptchaLoaded();

      // Execute reCAPTCHA with action "transcribe".
      const token = await executeRecaptcha("transcribe");

      const res = await fetch("/.netlify/functions/recaptcha", {
        method: "POST",
        body: token,
      });

      if (res.ok) {
        const openai = new openAiService();

        const transcriptionStartTime = new Date();

        const resp = await openai.transcribe(selectedFile.value);

        const endTime = new Date();
        const transcriptionDuration = (endTime - transcriptionStartTime) / 1000; // Duration in seconds

        // Log the duration to Google Analytics
        gtag.event("transcription_duration", {
          event_category: "Transcription",
          event_label: "Transcription Duration",
          value: transcriptionDuration,
        });

        ctx.emit("transcription-complete", resp.data);
        selectedFile.value = null;
        if (fileDropzoneRef.value) {
          fileDropzoneRef.value.clearFile();
        }

        gtag.event("success", {
          event_category: "Transcription",
          event_label: "Transcribe Success",
          value: 1,
        });

        gtag.event("length", {
          event_category: "Transcription",
          event_label: "Transcription Length",
          value: resp.data.text.length,
        });
      } else {
        errorMessage.value =
          "Invalid captcha. We basically couldn't validate you are a human!";

        gtag.event("failed", {
          event_category: "Transcription",
          event_label: "Transcribe Failed Not A Human",
          value: 1,
        });
      }

      isLoading.value = false; // Stop loading
    };

    return {
      errorMessage,
      selectedFile,
      isLoading,
      onFileChange,
      onErrorDismiss,
      transcribe,
      fileChangedHandler,
      errorHandler,
      fileDropzoneRef,
    };
  },
};
</script>

<style scoped>
p > span {
  font-weight: bolder;
}

.upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 2em 0;
  position: relative;
}

#fileUpload {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

#file-name {
  flex-grow: 1;
  border: 1px solid #ccc;
  padding: 10px;
}

#browse {
  padding: 10px 20px;
  background: #333;
  color: #fff;
  border: none;
  cursor: pointer;
}

#browse :disabled {
  background-color: #666;
}

#transcribe-button {
  padding: 15px 30px;
  background: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 1.4em;
  display: block;
  margin: 20px auto;
}

#transcribe-button:disabled {
  background-color: #80bfff;
}

#browse:disabled,
#transcribe-button:disabled {
  cursor: not-allowed;
  color: #999;
}

.language-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 20px;
}

.language-container select {
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
}

/* #acceptedFormats { */
/* margin-top: -1.2em; */
/* margin-bottom: 2em; */
/* } */
</style>
