import { createApp } from 'vue'
import App from './App.vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import router from './router'
import { createMetaManager, plugin as metaPlugin } from 'vue-meta'
import VueGtag from 'vue-gtag';
import { VueCookieNext } from 'vue-cookie-next';

const app = createApp(App)
app.use(router)
app.use(VueReCaptcha, { siteKey: '6Ldc1DInAAAAAA7odcbnfZ0TDIZ0LzKWsanEzrL-' })
app.use(createMetaManager())
app.use(metaPlugin)
app.use(VueCookieNext);
app.use(VueGtag, {
  config: { id: 'G-LK6606BFPF' },
  router,
  enabled: false,
});

app.provide('gtag', app.config.globalProperties.$gtag);
app.mount('#app')