<template>
  <main id="faq-view" class="content-wrapper">
    <h1>FAQ</h1>
    <p class="intro-description">
      Explore our frequently asked questions to learn more about
      <a href="https://speaktotext.io">SpeakToText.io</a>, our free online
      transcription tool powered by
      <a
        href="https://openai.com/research/whisper"
        target="_blank"
        rel="noopener noreferrer"
      >
        OpenAI's Whisper
      </a>
      model. If you have additional questions, please
      <a href="/contact-us">reach out</a> to our support team.
    </p>
    <div class="content long">
      <ul>
        <li>
          <h2>What is SpeakToText.io?</h2>
          <p>
            <b>SpeakToText.io</b> is a leading
            <a href="/">free online transcription tool</a> that effortlessly
            converts audio files into text using OpenAI's Whisper model, a
            cutting-edge solution for
            <b>audio-to-text transcription</b> ensuring the highest accuracy.
          </p>
        </li>
        <li>
          <h2>How much does the SpeakToText.io transcription service cost?</h2>
          <p>
            Our <a href="/">audio transcription service</a> is 100% free. With
            SpeakToText.io, you can enjoy premium
            <b>audio-to-text conversion</b> without any costs.
          </p>
        </li>
        <li>
          <h2>
            Do I need to register to transcribe audio to text on SpeakToText.io?
          </h2>
          <p>
            No sign-up is required! Our <b>free transcription service</b> allows
            users to convert audio to text without any email requirements or
            tedious registration processes.
          </p>
        </li>
        <li>
          <h2>How many languages can I transcribe with SpeakToText.io?</h2>
          <p>
            SpeakToText.io supports <b>transcription in 57 languages</b>.
            Whether you have an English podcast or a Spanish interview, our tool
            can handle your <b>multilingual transcription needs</b>.
          </p>
        </li>
        <li>
          <h2>
            Is there a limit to the audio length for transcription on
            SpeakToText.io?
          </h2>
          <p>
            There's no cap! Our <a href="/">online transcription tool</a> allows
            for unlimited minutes, ensuring that lengthy recordings, from
            podcasts to interviews, can be fully transcribed.
          </p>
        </li>
        <li>
          <h2>Which file formats can I upload for audio transcription?</h2>
          <p>
            We support a wide range of formats like mp3, mp4, wav, and m4a. With
            a size limit of 25MB, our platform is optimized for
            <b>high-quality audio and video transcription</b>.
          </p>
        </li>
        <li>
          <h2>How does SpeakToText.io guarantee accurate transcriptions?</h2>
          <p>
            Leveraging OpenAI's Whisper model, SpeakToText.io stands as one of
            the <b>best transcription services online</b> in terms of accuracy.
            Our <b>advanced audio-to-text algorithms</b> ensure top-notch
            results every time.
          </p>
        </li>
        <li>
          <h2>
            How does SpeakToText.io prioritize data security in transcription?
          </h2>
          <p>
            Your data's confidentiality is paramount. With SpeakToText.io, you
            can <a href="/">transcribe audio files</a> with peace of mind,
            knowing they're not stored on our servers. Plus, OpenAI's stringent
            data privacy policy ensures utmost security.
          </p>
        </li>
        <li>
          <h2>
            How fast is the audio to text transcription process on
            SpeakToText.io?
          </h2>
          <p>
            Our tool is engineered for real-time transcription, ensuring users
            receive their <b>text transcriptions</b> promptly, no matter the
            audio length.
          </p>
        </li>
        <li>
          <h2>Why is converting audio to text beneficial?</h2>
          <p>
            <b>Audio-to-text transcription</b> enhances content accessibility
            and readability, making it a valuable asset for journalists,
            students, and anyone needing a text version of spoken content.
          </p>
        </li>
        <li>
          <h2>
            I encountered an issue with my transcription. How can I get
            assistance?
          </h2>
          <p>
            For any transcription concerns, reach out to our dedicated support
            team by visiting our <a href="/contact-us">Contact Us</a> page.
            We're committed to ensuring a seamless
            <b>audio-to-text conversion experience</b> for all users.
          </p>
        </li>
        <li>
          <h2>Can SpeakToText.io's transcription tool be used commercially?</h2>
          <p>
            Absolutely! SpeakToText.io is an ideal solution for both individual
            and commercial <b>audio transcription needs</b>, offering
            unparalleled accuracy at no cost.
          </p>
        </li>
        <li>
          <h2>
            Does SpeakToText.io offer services beyond audio transcription?
          </h2>
          <p>
            Currently, our core expertise lies in providing the best
            <a href="/">free audio-to-text transcription service</a> online,
            ensuring premium quality and user satisfaction. That being said,
            rest assure as we are working on some pretty cool stuff in the near
            future.
          </p>
        </li>
      </ul>
    </div>
  </main>
</template>

<script>
export default {
  name: "Faq-View",
  computed: {
    faqSchema() {
      return {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        mainEntity: [
          {
            "@type": "Question",
            name: "What is SpeakToText.io?",
            acceptedAnswer: {
              "@type": "Answer",
              text: "SpeakToText.io is a leading free online transcription tool that effortlessly converts audio files into text using OpenAI's Whisper model, a cutting-edge solution for audio-to-text transcription ensuring the highest accuracy.",
            },
          },
          {
            "@type": "Question",
            name: "How much does the SpeakToText.io transcription service cost?",
            acceptedAnswer: {
              "@type": "Answer",
              text: "Our audio transcription service is 100% free. With SpeakToText.io, you can enjoy premium audio-to-text conversion without any costs.",
            },
          },
          {
            "@type": "Question",
            name: "Do I need to register to transcribe audio to text on SpeakToText.io?",
            acceptedAnswer: {
              "@type": "Answer",
              text: "No sign-up is required! Our free transcription service allows users to convert audio to text without any email requirements or tedious registration processes.",
            },
          },
          {
            "@type": "Question",
            name: "How many languages can I transcribe with SpeakToText.io?",
            acceptedAnswer: {
              "@type": "Answer",
              text: "SpeakToText.io supports transcription in 57 languages. Whether you have an English podcast or a Spanish interview, our tool can handle your multilingual transcription needs.",
            },
          },
          {
            "@type": "Question",
            name: "Is there a limit to the audio length for transcription on SpeakToText.io?",
            acceptedAnswer: {
              "@type": "Answer",
              text: "There's no cap! Our online transcription tool allows for unlimited minutes, ensuring that lengthy recordings, from podcasts to interviews, can be fully transcribed.",
            },
          },
          {
            "@type": "Question",
            name: "Which file formats can I upload for audio transcription?",
            acceptedAnswer: {
              "@type": "Answer",
              text: "We support a wide range of formats like mp3, mp4, wav, and m4a. With a size limit of 25MB, our platform is optimized for high-quality audio and video transcription.",
            },
          },
          {
            "@type": "Question",
            name: "How does SpeakToText.io guarantee accurate transcriptions?",
            acceptedAnswer: {
              "@type": "Answer",
              text: "Leveraging OpenAI's Whisper model, SpeakToText.io stands as one of the best transcription services online in terms of accuracy. Our advanced audio-to-text algorithms ensure top-notch results every time.",
            },
          },
          {
            "@type": "Question",
            name: "How does SpeakToText.io prioritize data security in transcription?",
            acceptedAnswer: {
              "@type": "Answer",
              text: "Your data's confidentiality is paramount. With SpeakToText.io, you can transcribe audio files with peace of mind, knowing they're not stored on our servers. Plus, OpenAI's stringent data privacy policy ensures utmost security.",
            },
          },
          {
            "@type": "Question",
            name: "How fast is the audio to text transcription process on SpeakToText.io?",
            acceptedAnswer: {
              "@type": "Answer",
              text: "Our tool is engineered for real-time transcription, ensuring users receive their text transcriptions promptly, no matter the audio length.",
            },
          },
          {
            "@type": "Question",
            name: "Why is converting audio to text beneficial?",
            acceptedAnswer: {
              "@type": "Answer",
              text: "Audio-to-text transcription enhances content accessibility and readability, making it a valuable asset for journalists, students, and anyone needing a text version of spoken content.",
            },
          },
          {
            "@type": "Question",
            name: "I encountered an issue with my transcription. How can I get assistance?",
            acceptedAnswer: {
              "@type": "Answer",
              text: "For any transcription concerns, reach out to our dedicated support team by visiting our Contact Us page. We're committed to ensuring a seamless audio-to-text conversion experience for all users.",
            },
          },
          {
            "@type": "Question",
            name: "Can SpeakToText.io's transcription tool be used commercially?",
            acceptedAnswer: {
              "@type": "Answer",
              text: "Absolutely! SpeakToText.io is an ideal solution for both individual and commercial audio transcription needs, offering unparalleled accuracy at no cost.",
            },
          },
          {
            "@type": "Question",
            name: "Does SpeakToText.io offer services beyond audio transcription?",
            acceptedAnswer: {
              "@type": "Answer",
              text: "Currently, our core expertise lies in providing the best free audio-to-text transcription service online, ensuring premium quality and user satisfaction. That being said, rest assure as we are working on some pretty cool stuff in the near future.",
            },
          },
        ],
      };
    },
  },
  mounted() {
    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.text = JSON.stringify(this.faqSchema);
    document.head.appendChild(script);
  },
};
</script>

<style scoped>
ul {
  list-style: none;
}

main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

main h1 {
  color: #333;
  padding-bottom: 20px;
}

main h2 {
  font-size: 1.2em;
  text-align: left;
  color: #38404b;
  border-bottom: 1px solid #f2f2f2;
  padding: 10px 0;
  margin-top: 20px;
}

main p {
  color: #666;
  margin: 10px 0;
  text-align: left;
}

.content {
  max-width: 800px;
  text-align: center;
  padding: 20px;
}

.intro-description {
  color: #555;
  margin-bottom: 20px;
  max-width: 800px;
  text-align: center;
  line-height: 1.8em;
  font-size: 1.2em;
}

.long {
  line-height: 1.4;
  font-size: 1.07em;
  padding: 1em 0 2em 0;
  max-width: 1000px;
}

.long h1 {
  font-size: 1.4em;
  text-align: left;
  color: #38404b;
  border-bottom: 2px solid #f2f2f2;
}

.long p {
  margin: 1em 0 1em 0;
}

.content-wrapper {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
