<template>
    <main id="privacy-policy-view">
      <h1>PRIVACY POLICY</h1>
      <p class="effective-date">Effective Date: July 22, 2023</p>
  
      <section>
          <h2>1. INTRODUCTION</h2>
          <p>This Privacy Policy ("Policy") is provided by xyfuture, LLC, the company behind the Speak to Text web application ("App", "we", "us", or "our"). We respect your privacy and are committed to protecting it through our compliance with this Policy. This Policy describes the types of information we may collect, how we use that information, and how we disclose it. Please read it carefully to understand our policies and practices regarding your information and how we treat it.</p>
      </section>
  
      <section>
          <h2>2. INFORMATION WE COLLECT</h2>
          <p>When you use our App, we may collect certain information about you. Primarily we collect the files you upload and your email address.</p>
      </section>
  
      <section>
          <h2>3. HOW WE USE YOUR INFORMATION</h2>
          <p>We use the information that we collect about you or that you provide to us, including your personal information:</p>
          <ul>
              <li>To provide you with the services available through our application.</li>
              <li>To transcribe audio files.</li>
              <li>To contact you with promotional newsletters provided you have opted to receive such communications.</li>
              <li>To serve ads via Google Ads, thereby helping us to monetize the free service we offer.</li>
          </ul>
      </section>
  
      <section>
          <h2>4. DISCLOSURE OF YOUR INFORMATION</h2>
          <p>We do not sell or share any user files or email addresses with third parties for their direct marketing purposes. However, we use OpenAI's Whisper-1 model to transcribe your files. As a result, your files may be shared with OpenAI in adherence to their privacy policy and terms of service.</p>
      </section>
  
      <section>
          <h2>5. THIRD-PARTY SERVICES</h2>
          <p>Our service integrates with Google Ads for advertisements, and OpenAI for its transcription services. These third-party providers have their own Privacy Policies regarding the data they receive from us to provide these services. We do not control these third parties' tracking technologies or how they are used.</p>
      </section>
  
      <section>
          <h2>6. SECURITY</h2>
          <p>We take measures to protect your personal data against accidental or unlawful destruction or accidental loss, alteration, unauthorized disclosure, or access. However, no method of transmission over the Internet, or method of electronic storage, is 100% secure.</p>
      </section>
  
      <section>
          <h2>7. CHILDREN'S PRIVACY</h2>
          <p>Our App is not designed for, nor do we believe it is appealing to, children under the age of 13. We don't knowingly collect personal information from children under 13.</p>
      </section>
  
      <section>
          <h2>8. CONTACT US</h2>
          <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:legal@speaktotext.io">legal@speaktotext.io</a>.</p>
      </section>
  
      <p class="ending-note">We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. Any changes we make to our Privacy Policy are effective as of the Effective Date and replace any prior Privacy Policies.</p>
  
      <p class="ending-note">Thank you for choosing Speak to Text.</p>
    </main>
  </template>
  
  <script>
  export default {
    name: "PrivacyPolicy-View",
    data() {
      return {};
    },
  };
  </script>
  
  <style scoped>
  #privacy-policy-view {
    width: 80%;
    margin: auto;
  }
  
  h1, h2 {
    color: #333;
  }
  
  h2 {
    margin-top: 2em;
  }
  
  .effective-date, .ending-note {
    font-weight: bold;
  }
  
  a {
    color: #1a0dab;
  }
  
  /* Add additional styles as needed */
  </style>