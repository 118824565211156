<template>
  <main id="contact-us-view">
    <div class="faq-note">
      <p>
        Before reaching out, please check our
        <a
          href="https://speaktotext.io/faq"
          target="_blank"
          rel="noopener noreferrer"
          >FAQ</a
        >
        to see if your question has already been answered.
      </p>
    </div>

    <div class="contact-form">
      <div class="notification" v-if="notificationMessage">
        {{ notificationMessage }}
      </div>

      <h2>Contact Us</h2>
      <!-- <form @submit.prevent="submitForm" data-netlify="true"></form> -->
      <form name="contact-us-form" method="POST" netlify>
        <input type="hidden" name="form-name" value="contact-us-form" />

        <div>
          <label for="name">Name:</label>
          <input
            id="name"
            v-model="name"
            type="text"
            required
            placeholder="Your full name"
            aria-label="Full Name"
          />
        </div>
        <div>
          <label for="email">Email:</label>
          <input
            id="email"
            v-model="email"
            type="email"
            required
            placeholder="you@example.com"
            aria-label="Email Address"
          />
        </div>
        <div>
          <label for="message">Message: ({{ message.length }}/1000)</label>
          <textarea
            id="message"
            v-model="message"
            required
            placeholder="Your message"
            maxlength="1000"
            aria-label="Your Message"
            @input="checkMessageLength"
          ></textarea>
        </div>
        <div>
          <label for="file">Attach a file (Max size: 25MB):</label>
          <div class="file-input">
            <input
              id="file"
              type="file"
              accept="image/*,.mp3,.mp4,.mpeg,.mpga,.m4a,.wav,.webm"
              aria-label="File Attachment"
            />
            <label for="file" id="browse">Browse...</label>
            <span id="file-name">{{ file ? file.name : "" }}</span>
          </div>
        </div>
        <div>
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  </main>
</template>

<script>
export default {
  name: "ContactUs-View",
  data() {
    return {
      name: "",
      email: "",
      message: "",
      file: null,
      notificationMessage: "",
    };
  },
  methods: {
    checkMessageLength() {
      if (this.message.length > 1000) {
        this.notificationMessage =
          "Message exceeds the maximum character limit!";
      } else {
        this.notificationMessage = "";
      }
    },

    onFileChange(e) {
      const file = e.target.files[0];
      const maxFileSize = 25 * 1024 * 1024; // 25MB

      if (file.size > maxFileSize) {
        alert("File is too large (max 25MB).");
        this.file = null;
      } else {
        this.file = file;
      }
    },
    submitForm() {
      if (!this.file || this.file.size <= 25 * 1024 * 1024) {
        console.log(
          "Form submitted:",
          this.name,
          this.email,
          this.message,
          this.file
        );

        this.name = "";
        this.email = "";
        this.message = "";
        this.file = null;

        // Feedback message
        this.notificationMessage = "Thanks! Your message has been submitted.";

        this.$gtag.event("click", {
          event_category: "Contact Us",
          event_label: "Form submitted.",
          value: 1,
        });
      } else {
        this.notificationMessage = "File is too large (max 25MB).";
      }
    },
  },
};
</script>
<style scoped>
main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch; /* Make the items stretch to full width */
  padding: 20px;
  width: 100%;
}

.faq-note p {
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.faq-note a {
  color: #007bff;
  text-decoration: underline;
  font-size: 1.2em;
}

.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto; /* Center the form */
  gap: 20px;
  width: 80%;
  max-width: 500px;
}

.notification {
  background-color: #e8f5e9; /* light green */
  border: 1px solid #4caf50; /* green */
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
}

.contact-form label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  margin-bottom: 20px;
}

.contact-form .file-input {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
}

.contact-form #browse {
  padding: 10px 20px;
  background: #333;
  color: #fff;
  cursor: pointer;
}

.contact-form #file-name {
  flex-grow: 1;
  border: 1px solid #ccc;
  padding: 10px;
}

.contact-form #file {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.contact-form button {
  padding: 15px 30px;
  background: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 1.4em;
  display: block;
  margin: 20px auto;
}

.contact-form button:disabled {
  background-color: #80bfff;
  cursor: not-allowed;
  color: #999;
}
</style>
