import { createRouter, createWebHistory } from "vue-router";
import Home from "./views/Home.vue";
import PrivacyPolicy from "./views/PrivacyPolicy.vue";
import TermsOfService from "./views/TermsOfService.vue";
import ContactUs from "./views/ContactUs.vue";
import FAQ from "./views/Faq.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      component: PrivacyPolicy,
    },
    {
      path: "/terms-of-service",
      name: "terms-of-service",
      component: TermsOfService,
    },
    {
      path: "/contact-us",
      name: "contact-us",
      component: ContactUs,
    },
    {
      path: "/faq",
      name: "faq",
      component: FAQ,
    },
  ],
});

export default router;
