"use strict";
// Not a good practice, follow https://github.com/openai/openai-node/issues/75
class CustomFormData extends FormData {
    getHeaders() {
        return {}
    }
}

const { Configuration, OpenAIApi } = require("openai");
const Config = require("../config")

const configuration = new Configuration({
    apiKey: Config.openai.whisper.apiKey,
    formDataCtor: CustomFormData
})

class OpenAiService {
    constructor() {
        this.openai = new OpenAIApi(configuration)
    }

    async transcribe(file, model='whisper-1') {
        return await this.openai.createTranscription(file, model, 'vtt')
    }
}

module.exports = OpenAiService