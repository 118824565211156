'use strict';

const config = {
    global: {
        maxFileSize: parseInt(process.env.VUE_APP_GLOBAL_MAXFILESIZE) || 25 // File size in megabytes
    },

    openai: {
        whisper: {
            apiKey: process.env.VUE_APP_OPENAI_WHISPER_APIKEY,
            model: process.env.VUE_APP_OPENAI_WHISPER_MODEL || 'whisper-1'
        }
    }
};

module.exports = config;
