<template>
    <div id="error-notification">
        <p>{{errorMsg}}
            <button @click="dismiss" class="dismiss-btn">X</button>
        </p>
    </div>
</template>

<script>
export default {
    name: 'ErrorNotification',
    props: {
        errorMsg: String,
        onDismiss: Function,
    },
    methods: {
        dismiss() {
            this.onDismiss();
        }
    }
}
</script>

<style scoped>
#error-notification {
    background-color: #f8d7da;
    color: #721c24;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #f5c6cb;
    text-align: left;
}

#error-notification p {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dismiss-btn {
    background-color: rgba(114, 28, 36, 0.7);
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 2px 5px;
    font-size: 14px;
    font-weight: bold;
    margin-left: auto;
    border-radius: 3px;
    transition: background-color 0.3s ease;
}

.dismiss-btn:hover {
    background-color: rgba(114, 28, 36, 0.9);
}

</style>