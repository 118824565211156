<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? `${content}` : `SITE_NAME`
    }}</template>
  </metainfo>
  <AppHeader title="SpeakToText" />

  <router-view />

  <AppFooter email="hello@speaktotext.io" />

  <CookieBanner></CookieBanner>
</template>

<script>
import { ref } from "vue";
import AppHeader from "./components/Header.vue";
import AppFooter from "./components/Footer.vue";
import CookieBanner from "./components/CookieBanner.vue";
import { useMeta } from "vue-meta";

export default {
  name: "App",
  components: {
    AppHeader,
    AppFooter,
    CookieBanner,
  },
  setup() {
    const title = ref(
      "SpeakToText.io - Best Free Audio to Text Transcription Tool"
    );
    const description = ref(
      "SpeakToText.io - your number one tool for multi-language audio transcription. Super accurate, super easy-to-use. Simply upload and transcribe! Completely free."
    );

    useMeta({
      title: title.value,
      htmlAttrs: { lang: "en", amp: true },
      meta: [
        {
          vmid: "description", // ensures this meta tag is overwriteable
          name: "description",
          content: description.value,
        },
        {
          vmid: "robots",
          name: "robots",
          content: "index, follow",
        },
        {
          vmid: "language",
          name: "language",
          content: "English",
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: title.value,
        },
        {
          vmid: "og:description",
          property: "og:description",
          content:
            "Transcribe audio files to text in any language with SpeakToText.io - an extremely accurate, easy-to-use transcription tool using OpenAI Whisper model. Try it free today!",
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: "HTTPS://WWW.SPEAKTOTEXT.IO",
        },
        {
          vmid: "og:site_name",
          property: "og:site_name",
          content: "SpeakToText.io",
        },
        // {
        //   vmid: "og:card",
        //   property: "og:card",
        //   content: "<TODO: summary_large_image>",
        // },
        // {
        //   vmid: "og:image",
        //   property: "og:image",
        //   content: "<TODO: URL_TO_THE_IMAGE_THAT_REPRESENTS_YOUR_BUSINESS>",
        // },
      ],
    });

    return { title, description };
  },
};
</script>
<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: #2c3e50;
  justify-content: space-between;
}

body,
html {
  height: 100%;
  margin: 0;
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

@media (max-width: 600px) {
  header nav,
  footer nav {
    display: flex;
    flex-direction: column;
  }
}
</style>
