<template>
  <div
    class="dropzone"
    :class="{ 'dropzone--disabled': isDisabled }"
    @click="triggerFileInput"
    @drop.prevent="onDrop"
    @dragover.prevent
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-upload icon-upload"
    >
      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
      <polyline points="17 8 12 3 7 8"></polyline>
      <line x1="12" y1="3" x2="12" y2="15"></line>
    </svg>

    <span v-if="selectedFile">{{ selectedFile.name }}</span>
    <span v-else>Click or Drag File to Upload</span>
    <form>
      <input
        type="file"
        ref="fileInput"
        @change="onFileChange"
        style="display: none"
        accept=".mp3, .mp4, .mpeg, .mpga, .m4a, .wav, webm"
        :disabled="isDisabled"
      />
    </form>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "FileDropzone",
  props: ["maxFileSize", "isDisabled"],
  emits: ["file-changed", "error"],
  setup(props, ctx) {
    const selectedFile = ref(null);
    const fileInput = ref(null);

    const clearFile = () => {
      selectedFile.value = null;
    };

    const triggerFileInput = () => {
      if (props.isDisabled) return;

      fileInput.value.click();
    };

    const onDrop = (event) => {
      if (props.isDisabled) return;

      const files = event.dataTransfer.files;
      onFileChange({ target: { files } });
    };

    const onFileChange = (e) => {
      if (props.isDisabled) return;

      let files = e.target.files;
      if (!files.length) return;

      if (files[0].size > props.maxFileSize * 1048576) {
        ctx.emit(
          "error",
          `File is too big! The maximum allowed size is ${props.maxFileSize}MB.`
        );
        e.target.value = "";
        return;
      }

      selectedFile.value = files[0];
      ctx.emit("file-changed", selectedFile.value);
    };

    return {
      selectedFile,
      fileInput,
      triggerFileInput,
      onDrop,
      clearFile,
      onFileChange,
    };
  },
};
</script>

<style scoped>
.dropzone {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  position: relative;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.dropzone:hover {
  background-color: #f8f8f8;
}

.dropzone--disabled {
  background-color: #f0f0f0;
  cursor: not-allowed;
}

.icon-upload {
  font-size: 24px;
  color: #ccc;
  width: 50px;
  height: 50px;
}
</style>
