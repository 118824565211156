<template>
  <footer>
    <div class="footer-content">
      <p class="contact-email">
        <a
          :href="`mailto: ${email}`"
          onclick="ga('send', 'click', 'Contact', 'click', 'Hello Email Tap', '1');"
          >{{ email }}</a
        >
      </p>
      <nav>
        <ul>
          <li>
            <router-link to="/privacy-policy">Privacy Policy</router-link>
          </li>
          <li>
            <router-link to="/terms-of-service">Terms of Use</router-link>
          </li>
          <li>
            <router-link to="/faq">FAQs</router-link>
          </li>
        </ul>
      </nav>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
  props: {
    email: String,
  },
};
</script>

<style scoped>
footer {
  background: #f4f4f4;
}

footer p {
  margin: 0;
  color: #333;
}

.footer-content {
  max-width: 800px;
  height: 100px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-content ul {
  list-style: none;
}

.footer-content ul li {
  margin-bottom: 10px;
  font-size: 1.07em;
}

.contact-email {
  font-size: 1.3em;
  font-weight: bold;
}

.contact-email > a {
  text-decoration: none;
  color: inherit;
}

footer nav a {
  color: #333;
  text-decoration: none;
}

footer nav a:hover {
  text-decoration: underline;
}

/* footer nav a:not(:last-child) {
    margin-right: 5px;
}

footer nav a:not(:last-child)::after {
    content: '|';
    margin-left: 5px;
} */
</style>
